/* src/index.css */

/* Slick Slider CSS */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Additional global styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: 'transparent';
}

a {
  text-decoration: none;
  color: inherit;
}
