table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; /* Allows border-radius to work */
  border: 1px solid #ddd;
  border-radius: 10px; /* Add rounded corners */
  overflow: hidden; /* Hide any overflow */
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}


/* Handle border-radius for the corners */
table tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

table tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
